import React from 'react';
import { Link, graphql } from 'gatsby';
import { renderToReact } from '../rich-text';

import Layout from '../components/layout';
import SEO from '../components/seo';

import styles from './project.module.css';

export default ({ data }) => (
  <Layout>
    <SEO title={data.project.name} />
    <div className={styles.container}>
      {data.project.projects_page && data.project.projects_page[0] && (
        <p>
          <Link
            to={data.project.projects_page[0].slug}
            className={styles.backLink}
          >
            Back to {data.project.projects_page[0].name}
          </Link>
        </p>
      )}
      {data.project.body && renderToReact(data.project.body)}
    </div>
  </Layout>
);

export const query = graphql`
  query($id: String!) {
    project: contentfulProject(id: { eq: $id }) {
      name
      body {
        json
        embeds {
          internal {
            type
          }
          ... on ContentfulAsset {
            fields {
              normalizedContentfulId
            }
            file {
              url
              contentType
            }
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyContentfulFluid_tracedSVG
              aspectRatio
            }
          }
          ... on ContentfulLayout {
            fields {
              normalizedContentfulId
            }
            body {
              json
            }
            styles {
              fields {
                array {
                  key
                  value
                }
              }
            }
          }
          ... on ContentfulGalleryWithCaptionsWidget {
            fields {
              normalizedContentfulId
            }
            entries {
              id
              asset {
                file {
                  url
                  contentType
                }
                fluid(maxWidth: 2000, quality: 100) {
                  ...GatsbyContentfulFluid_tracedSVG
                  aspectRatio
                }
              }
              caption {
                json
              }
            }
          }
          ... on ContentfulGalleryWidget {
            fields {
              normalizedContentfulId
            }
            entries {
              id
              file {
                url
                contentType
              }
              fluid(maxWidth: 2000, quality: 100) {
                ...GatsbyContentfulFluid_tracedSVG
                aspectRatio
              }
            }
          }
          ... on ContentfulSideBySideWidget {
            fields {
              normalizedContentfulId
            }
            entries {
              id
              file {
                url
                contentType
              }
              fluid(maxWidth: 2000, quality: 100) {
                ...GatsbyContentfulFluid_tracedSVG
                aspectRatio
              }
            }
          }
        }
      }
      projects_page {
        name
        slug
      }
    }
  }
`;
